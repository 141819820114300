import {
  Table,
  TableRow,
  Loader,
  Tag,
  EmptyState,
  Dialog,
  Text,
  Button,
  Spacer,
  TextAreaField,
  Flexbox,
  useToast,
  Breadcrumbs,
  GridItem,
  BreadcrumbsItem,
  Grid,
  Switch,
  Search,
  Pagination,
} from "@skf-internal/ui-components-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ViewUserLink from "../global-roles/ViewUserLink";
import {
  applicationUserAsync,
  countriesAsync,
  domainsAsync,
  IFetchCountries,
  IFetchDomains,
  IRoleItem,
  IUserBasedOnRoles,
  selectApplicationRoleUserCountries,
  selectApplicationRoleUserDomains,
  selectRoles,
  selectUsers,
  selectUserStatus,
} from "./applicationDetailsSlice";

import { IApplication } from "../applications/Applications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectAuth } from "../sso/ssoSlice";
import { selectApps } from "../applications/applicationsSlice";
import { useDebounce } from "../common/useComplexUserSearch";
import { getCountryName, getFlag } from "../../Utils/getflag";
import ApplicationRolesUserFilter from "./ApplicationRoleUserFilter";
import { IDateFilterValue } from "../common/DateFilter";
import RolesUserFilterTags, {
  IRolesUserFilter,
} from "../common/RoleUsersFilterTags";
import { displayDate } from "../common/Common";
import CustomTable from "../common/CustomTable";
import { createUsersRows } from "./applicationRoleUserFunctions";


export default function AppRoleUsers() {
  const [hasMore, setHasMore] = useState(false);
  const [loading , setLoading] = useState(true);
  const [initialLoadingComplete , setInitialLoadingComplete] =useState(false);
  const location = useLocation();
  const { appId = "", roleId = "" } = useParams();

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const { addToast } = useToast();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);
  const auth = useAppSelector(selectAuth);
  const apps = useAppSelector(selectApps);
  const roles = useAppSelector(selectRoles);

  const domains = useAppSelector(selectApplicationRoleUserDomains);
  const countries = useAppSelector(selectApplicationRoleUserCountries);
  const initialApp = {} as IApplication;
  const initialRole = {} as IRoleItem;
  const [app, setApp] = useState<IApplication>(initialApp);
  const [role, setRole] = useState<IRoleItem>(initialRole);
  const [quickFilter, setQuickFilter] = useState("");
  const [includeInternalUser, setIncludeInternalUser] = useState(false);
  const [initInProgress, setInitInProgress] = useState(true);
  const searchStatus = useAppSelector(selectUserStatus);
  const observer = useRef<any>();
  const [startInfiniteScroll , setStartInfiniteCall] =useState(false);
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  const navigate = useNavigate();
  const gotoRoute = (route: string) => {
    navigate(route);
  };
  const [lastLoginFilter, setLastLoginFilter] = useState<IDateFilterValue>();
  const [filteredDomains, setFilteredDomains] = useState<string[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);
  const [createdDateFilter, setCreatedDateFilter] =
    useState<IDateFilterValue>();
  const [sortingValues, setSortingValues] = useState(["", ""]);
  const [page, setPage] = useState(1);
  const [stateUsers , setStateUsers] = useState<any>([]);
  const [stateUsersTotal , setStateUsersTotal] =useState<any>(0);
  const initial: TableRow[] = [];
  const [userRows, setUserRows] = useState(initial);
  const lastItemRef = useCallback((node: any) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && initialLoadingComplete ) {
       
         setPage(prevPage => prevPage + 1);
         setStartInfiniteCall(true)
      }
    });
    if (node) observer.current.observe(node);
  }, [ loading , hasMore , initialLoadingComplete] );


  //Sorting
  const [sortingFirstName, setSortingFirstName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastName, setSortingLastName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingEmail, setSortingEmail] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCompany, setSortingCompany] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCountry, setSortingCountry] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastLogin, setSortingLastLogin] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCreationDate, setSortingCreationDate] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
let initialCallTriggered = false; 
  
  useEffect(() => {
    if( initialCallTriggered){
      return;
    }
    initialCallTriggered = true; 

    console.log("Initial effect");

    if (auth?.idTokenClaims?.roles) {
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }
    }


    loadAvailableDomains();
    loadAvailableCountries();
    let filterApps = apps.filter((p) => p.applicationId == appId);
    if (filterApps.length > 0) {
      setApp(filterApps[0]);
    }

    let filteredRoles = roles.filter((p) => p.roleId === parseInt(roleId + ""));
    if (filteredRoles.length > 0) {
      setRole(filteredRoles[0]);
    }
    setTimeout(function () {
      setInitInProgress(false);
    }, 100);
    setBreadc();
  }, []);


  useEffect(() => {
    if (domains.total > 0) {
      applicationRoleUserFilters.availableDomains = domains.data;
    }
  }, [domains]);
  useEffect(() => {
    if (countries.total > 0) {
      applicationRoleUserFilters.availableCountries = countries.data;
    }
  }, [countries]);
  const debouncedOnChange = useDebounce(() =>  loadUsers(false, false, sortingValues,page), 200);
  const filterUsers = () => {
    console.log("filter changed");
    if (!initInProgress) {
      try {
        debouncedOnChange();
      } catch (err) {
        console.error(err);
      }
    }
  };
  
  const debouncedOnFilterChange = useDebounce(filterUsers, 900);
  useEffect(() => {
    console.log("filter changed trigger");
    if ( (  quickFilter?.length > 2 || quickFilter?.length == 0 )) {
      // debouncedOnChange()
      if(initialLoadingComplete){
        setPage(1);
        setApplicationRolesUserFilter({...applicationRoleUserFilters , includeInternalUser : includeInternalUser , availableDomains : domains?.data, availableCountries: countries?.data});
      }
      initialLoadingComplete ?  debouncedOnChange() : loadUsers(  true , true, sortingValues,1) ;
    }
 }, [quickFilter , includeInternalUser, sortingValues]);

  
  const reloadCallback = useCallback(
    () => { debouncedOnChange(),
            setPage(1);
     },
    
    [sortingValues, quickFilter, includeInternalUser, page , debouncedOnChange , setPage ]
  );
  //fetch for infinite scroll
  useEffect (()=> {
    if(startInfiniteScroll && hasMore && initialLoadingComplete ){
      
      loadUsers(  true , false, sortingValues,page) ;

    }
  } , [ startInfiniteScroll , page ] )

let loadingUsersOngoing = false; 
  const loadUsers = useCallback( async (resetEnable = true , initialCall=false, sortingValues=["",""], pageParam=1) => {
   
    if (searchStatus === "loading" || loadingUsersOngoing) {
       return;
    }
    console.log("load User" );
    loadingUsersOngoing = true; 
    if (auth?.idTokenClaims && roleId != null) {
      let fetchUsers: IUserBasedOnRoles = {
        roleId: roleId,
        applicationId: appId,
        adminId: auth.idTokenClaims.sub,
        queryPage: page,
        itemsPerPage: 30,
       // itemsPerPage:  itemsPerPage() > 5 ? itemsPerPage()  : 5,
        sortByField: sortingValues[0],
        order: sortingValues[1] == "descending" ? "desc" : "asc",
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
        filterByDomains: filteredDomains,
        filterByCountries: filteredCountries,
        
      };

      setLoading (true);
      setStartInfiniteCall(false);
      const response  = await dispatch(applicationUserAsync(fetchUsers)).unwrap();
     
       setStateUsersTotal(response.total);
       if(response.page < response.last_page) {
        setHasMore(true);
       }else{
        setHasMore(false);
       }
       if(startInfiniteScroll && hasMore ){
         setStateUsers( (prevState: any)=> [...prevState , ...response?.data] )
       } else {
         setStateUsers( response.data)
       }
       if (initialCall) {
        setInitialLoadingComplete(true);
      } 
        loadingUsersOngoing= false; 
        setLoading (false);
    }
   
  } , [page ,  hasMore , startInfiniteScroll , filteredDomains , filteredCountries , createdDateFilter ,lastLoginFilter , includeInternalUser , quickFilter ]);
  
  
  const handleUserInputChange = (value: string) => {
    setQuickFilter(value);
    setPage(1);
  };


  const loadAvailableDomains = () => {
    if (roleId != null) {
      let fetchDomains: IFetchDomains = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        applicationId: appId,
        level: "",
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
      dispatch(domainsAsync(fetchDomains));
    }
  };
  const loadAvailableCountries = () => {
    if (roleId != null) {
      let fetchContries: IFetchCountries = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        applicationId: appId,
        level: "",
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
      dispatch(countriesAsync(fetchContries));
    }
  };
  useEffect(() => {
    if (!initInProgress) {
      debouncedOnChange();
    }
    applicationRoleUserFilters.includeInternalUser = includeInternalUser;
  }, [quickFilter, sortingValues, includeInternalUser, page]);



  useEffect(() => {
    if (searchStatus === "failed") {
      addToast({
        children: "Something went wrong!! Pleasse try again later",
        feSeverity: "error",
      });
    }
  }, [searchStatus]);

  useEffect(() => {
    setBreadc();
  }, [app, role]);

  const setBreadc = () => {
    let bc = [
      {
        label: "Application",
        href: "/app",
        onClick: (e, route) => gotoRoute(route),
      },
      {
        label: `${app.applicationName}`,
        href: "/app/" + app?.applicationId,
        onClick: (e, route) => gotoRoute(route),
      },
      {
        label: `${role.roleName}`,
        href: "/app/" + app?.applicationId + "/roles/" + roleId + "/users",
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    ] as BreadcrumbsItem[];

    setBreadcrumbs(bc);
  };
  // -------------------Need to add in common js --------------------------
  

  const [applicationRoleUserFilters, setApplicationRolesUserFilter] =
    useState<IRolesUserFilter>({
      includeInternalUser: includeInternalUser,
      creatonDateFilter: {} as IDateFilterValue,
      lastLoginDateFilter: {} as IDateFilterValue,
      filteredDomains: [],
      filteredCountries: [],
      availableDomains: [],
      availableCountries: []
    } as IRolesUserFilter);

  const applyFilter = () => {
    setPage(1);
    setLastLoginFilter(applicationRoleUserFilters.lastLoginDateFilter);
    setCreatedDateFilter(applicationRoleUserFilters.creatonDateFilter);
    setFilteredDomains(applicationRoleUserFilters.filteredDomains);
    setFilteredCountries(applicationRoleUserFilters.filteredCountries);
    debouncedOnChange();
  };
  




  //Table definition
  const sortableFields = [
    {"field": "firstName", "label":"First name", "order": sortingFirstName, "setOrderCallback": setSortingFirstName, "width": "150"}, 
    {"field": "lastName",  "label":"Last name","order": sortingLastName,  "setOrderCallback": setSortingLastName, "width": "150"}, 
    {"field": "email",     "label":"Email","order": sortingEmail, "setOrderCallback": setSortingEmail, "width": "150"}, 
    {"field": "country",   "label":"Country","order": sortingCountry, "setOrderCallback": setSortingCountry, "width": "90"}, 
    {"field": "company",   "label":"Company","order": sortingCompany, "setOrderCallback": setSortingCompany, "width": "150"},
    {"field": null , "label":"Application role", "width": "400", className:"TagCell"}, 
    {"field": "lastLogin", "label":"Last login","order": sortingLastLogin, "setOrderCallback": setSortingLastLogin, "width": "150"}, 
    {"field": "creationDate", "label":"Creation date", "order": sortingCreationDate, "setOrderCallback": setSortingCreationDate, "width": "150"}, 
    {"field": null , "label":"More", "width": "400", className:"TagCell"}, 
  ]
  
  //End table definition
  //Create table data
  useEffect(() => {
   // No APi is calling only forming the table row
   setUserRows(createUsersRows(stateUsers, auth, Number(roleId), lastItemRef, reloadCallback, isSuperAdmin,appId));
  }, [stateUsers ]);

  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={breadcrumbs as BreadcrumbsItem[]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />
      <Spacer />

      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <div style={{ width: "100%" }}>
              <Flexbox feGap="md" feJustifyContent="space-between">
                <div style={{ width: "60rem" }}>
                  <Search
                    className="userSearch"
                    feLabel=""
                    feResetButton={{
                      "aria-label": "Rensa",
                      onClick: () => setQuickFilter(""),
                    }}
                    placeholder={
                      "Search by first name, last name, company, country and/or e-mail"
                    }
                    onChange={(_, value) => {
                      setQuickFilter(value);
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        handleUserInputChange(quickFilter);
                      }
                    }}
                    value={quickFilter}
                    //disabled={searchStatus !== "idle"}
                  />
                  <div className="filterTag">
                    <RolesUserFilterTags
                      setIncludeInternalUser={setIncludeInternalUser}
                      filterData={applicationRoleUserFilters}
                    />
                  </div>
                </div>
                <ApplicationRolesUserFilter
                  filterData={applicationRoleUserFilters}
                  updateOnApply={applyFilter}
                  setIncludeInternal={setIncludeInternalUser}
                />
              </Flexbox>
              <Spacer feSpacing="xxs" />
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  padding: "0.1rem",
                  textAlign: "left",
                  height: "calc(100vh - 13.9rem)"
                }}
              >
                {/* {searchStatus === "loading" && <Loader />} */}
                {status === "failed" && (
                  <p>Search failed. Please logout and logon again</p>
                )}

                     <CustomTable rawData={stateUsers} dataRows = {userRows} headerCells={sortableFields} setSortingValuesCallback={setSortingValues}/>
             
                 {loading &&  <div style={{display:'flex' , justifyContent: 'center', position:'absolute', left:'50%', top:'50%'}}>   <Loader  />   </div>}
                
              </div>
              <Flexbox feJustifyContent="flex-end">
              <span> {stateUsers.length } / {stateUsersTotal} </span>
              </Flexbox>
            </GridItem>
            </div>
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
