import { Button, Confirm, Dialog, IconButton, Table, TableCell, TableRow, useToast } from "@skf-internal/ui-components-react";
import { useRef, useState } from "react";

export interface ICustomTable {
    dataRows: any[];
    headerCells: any[];
    setSortingValuesCallback: any; 
    rawData?:any
  }
  
  export default function CustomTable (props: ICustomTable) {
    const {dataRows, headerCells, setSortingValuesCallback} = props;
    const tableRef = useRef <HTMLTableElement>(null);
    const [openClosed , setOpenClosed] =  useState(false);
    const { toasts, addToast, removeToast } = useToast();
    
    const sortableHead = () => {
        let headers:TableCell[] = [];
        
        headerCells.forEach(field => {
          if (field.field != null){
            headers.push(createColumnConfig(field));
          } else {
            headers.push(createColumnConfigNoSort(field));
          }
        });
        return [{ cells: headers}] as TableRow[]; 
      }
    
      
      function createColumnConfig(fieldInfo:any) {
        return {
          children: fieldInfo.label,
          scope: "col",
          width: fieldInfo.width?fieldInfo.width + "px":"",
          className: "Sort-" + fieldInfo.order,
          onClick: () => {
            tableSort(fieldInfo.field, headerCells);
          },
        } as TableCell;
      }
      function createColumnConfigNoSort(fieldInfo:any) {
        return {
          children: fieldInfo.label,
          scope: "col",
          width: fieldInfo.width?fieldInfo.width + "px":"",
          className: fieldInfo.className??""
        } as TableCell;
      }

      function clearSort(exclude: string, headers: any[]) {
        headers.forEach(fieldInfo => {
          if (fieldInfo.field && exclude !== fieldInfo.label){
            fieldInfo.setOrderCallback("undefined");
          }
        })
      }
      function toggleSearch(currentValue: string) {
        var newValue: "undefined" | "ascending" | "descending" = "undefined";
        switch (currentValue) {
          case "undefined":
            newValue = "ascending";
            break;
          case "ascending":
            newValue = "descending";
            break;
          case "descending":
            newValue = "undefined";
            break;
        }
    
        return newValue;
      }
      const tableSort = (fieldName: string, headers: any[]) => {
        var order = undefined;
        let fieldInfo:any = headers.find(f=> f.field == fieldName); 
        order = toggleSearch(fieldInfo.order); 
        fieldInfo.setOrderCallback(order); 
        clearSort(fieldInfo.label, headers);
    
        console.log("Sorting value " + fieldName + " "+ order);
        setSortingValuesCallback ([fieldName, order + ""]);
      };
     
      //formating for clipboard 
      const formatDataAsTSV = (data: any[]) => {
        if (!data || data.length === 0) return '';
        const headers = Object.keys(data[0])?.filter(el=> el !== 'roles' && el !== 'applicationRoles' );

      const rows = data.map(item => headers.map(header => {
        return item[header] === null ? '' : item[header];
      }).join('\t'));
  
      return [headers.join('\t'), ...rows].join('\n');
    };

    //copy to the clipboard 
      const handleCopy = ()=> {
        const tsvData = formatDataAsTSV(props.rawData);
        navigator.clipboard.writeText(tsvData).then(() => {
        
          addToast({
            children: 'Information copied to your clipboard !!',
            feSeverity: 'success',

        });
          setOpenClosed(false);
        }).catch(err => {
          console.error('Failed to copy data: ', err);
        });
       
      }
 
return (
  <>
    <Table
      feBody={dataRows}
      feHead={sortableHead()}
      feCompact
      feScrollable={true}
    />
   <span style={{display:"inline-flex", width:'10rem' , justifyContent:'space-evenly', marginTop:"0.5rem"}}> 
    <IconButton
    onClick={() => setOpenClosed(!openClosed)}
    aria-label="Settings"
    feSize="lg"
    as="button"
    feIcon="copy"
    title="Copy to clipboard"
    feColor="brand"
   />
   Copy to clipboard
   </span>
    <Dialog
      feTitle="Read the information carefully"
      open={openClosed}
      feOnClose={() => setOpenClosed(false)}
      feInterruptive={true}
    >
      <p>
        You are about to copy personal data into your clipboard. Please be aware
        you must always comply with the <b> GDPR</b> data protection regulation
        when processing any personal data!
      </p>
      <section style={{display: "flex" , justifyContent:"flex-end"}}> 
      <Button style={{marginRight:"0.7rem"}} feType={"secondary"} feSize="sm" onClick={() => setOpenClosed(false)}>
        Cancel
      </Button>

      <Button feSize="sm" onClick={() => handleCopy()}>
        Copy to Clipboard
      </Button>
      </section>
    </Dialog>
  </>
);

  } 